import { DateTime } from 'luxon';

export const startOfEducationYear = () =>
  DateTime.now()
    .minus({ year: DateTime.now().month < 7 ? 1 : 0 })
    .set({ month: 9, day: 1 });

export const endOfEducationYear = () =>
  startOfEducationYear().plus({ year: 1 }).minus({ month: 2 });

export const isEducationDate = (date: DateTime) =>
  !(
    endOfEducationYear().toUnixInteger() > DateTime.now().toUnixInteger() &&
    date.toUnixInteger() <= startOfEducationYear().toUnixInteger()
  );

export const currentScheduleWeek = () =>
  (isEducationDate(DateTime.now()) ? DateTime.now() : startOfEducationYear())
    .startOf('week')
    .startOf('day');

export const educationWeeksInYear = () =>
  Math.floor(
    Math.abs(endOfEducationYear().diff(startOfEducationYear(), 'weeks').weeks),
  );
